const catName = [
  "Birds of Prey",
  "Exotic Mammals",
  "Crocodilians",
  "Birds",
  "Monkeys",
  "Mushrooms",
  "Octopus",
  "Weasels",
  "Fox",
  "Spiders",
  "Venomous Snakes",
  "Fruits",
  "Vegetables"
];
export default catName;
