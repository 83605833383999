import { Grid, Typography } from "@mui/material";
import React from "react";
import { imageListItemClasses } from "@mui/material/ImageListItem";
import ListCard from "./ListCard";

const CardListing = ({ listData, classes, view }) => {
  return (
    <>
      {listData.length > 0 ? (
        <Grid
          sx={{
            margin: "10px",
            width: "100%",
            backgroundColor: "transparent",
            mt: 2,
            display: "grid",
            gap: 2,
            gridTemplateColumns: {
              xs: "repeat(2, minmax(0, 1fr))",
              sm: "repeat(3, minmax(0, 1fr))",
              md: "repeat(4, minmax(0, 1fr))",
              lg: "repeat(5, minmax(0, 1fr))",
            },
            [`& .${imageListItemClasses.root}`]: {
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <ListCard listData={listData} classes={classes} view={view} />
        </Grid>
      ) : (
        <Typography textAlign={"center"} sx={{ fontWeight: "bold" }}>
          {" "}
          No one has created such listings yet.
        </Typography>
      )}
    </>
  );
};

export default CardListing;
