import React, { useEffect, useState } from "react";
import { Avatar, Box, Container, Grid, Typography } from "@mui/material";
import SwiperCore, { Autoplay, Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import catName from "../../pages/categories/ComingSoonCategories";
const Categories = ({ classes, page }) => {
  SwiperCore.use([Autoplay]);
  const [categoryId, setCategoryId] = useState(-1);
  const currentLocation = useLocation();

  useEffect(() => {
    if (currentLocation.pathname === "/listings") {
      setCategoryId(-1);
    }
  }, [currentLocation]);

  const setSelectedCategory = (id) => {
    setCategoryId(id);
  };

  return (
    <Box
      sx={{
        backgroundColor: page == "Home" ? "#efefef" : "#ffff",
        paddingY: page == "Home" ? "60px" : "30px",
      }}
    >
      <Container fixed maxWidth={false}>
        <Swiper
          style={{
            "--swiper-navigation-color": "rgb(255, 192, 1)",
            "--swiper-pagination-color": "rgb(255, 192, 1)",
          }}
          navigation={true}
          modules={[Navigation, Scrollbar]}
          grabCursor={true}
          autoplay={true}
          loop={true}
          slidesPerView={page == "Home" ? 6 : 8}
          breakpoints={{
            320: { slidesPerView: 1 },
            480: { slidesPerView: 1 },
            600: { slidesPerView: 2 },
            900: { slidesPerView: 4 },
            1200: { slidesPerView: 5 },
            1500: { slidesPerView: page == "Home" ? 6 : 8 },
          }}
        >
          {classes &&
            classes.map((cat) => {
              return (
                <>
                  {!catName.includes(cat.name) && (
                    <SwiperSlide>
                      <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 100 }}
                        transition={{ duration: 1 }}
                      >
                        <Grid
                          item
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          direction="column"
                          xs={12}
                          sm={12}
                          md={0}
                        >
                          <Box
                            component={Link}
                            to={
                              page !== "Home"
                                ? page == "Listing"
                                  ? `/listings/classes/${cat.id}`
                                  : page == "Services"
                                  ? `/services/listings/classes/${cat.id}`
                                  : `*`
                                : `/classes/${cat.id}/breeds`
                            }
                            onClick={() => setSelectedCategory(cat.id)}
                            p={1}
                            sx={{
                              borderRadius: "50%",
                              border:
                                categoryId === cat.id && "3px solid #FFC001",
                              backgroundColor:
                                categoryId === cat.id && "#D8D8D8",
                            }}
                          >
                            <Avatar
                              key={cat.image}
                              alt={cat.image}
                              style={{
                                width: page == "Home" ? "125px" : "70px",
                                height: page == "Home" ? "125px" : "70px",
                                backgroundColor: "black",
                              }}
                            >
                              <img
                                src={cat.image}
                                alt={cat.image}
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  objectFit: "contain",
                                  backgroundColor: "black",
                                }}
                              />
                            </Avatar>
                          </Box>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "18px",
                                sm: page == "Home" ? "24px" : "18px",
                              },
                              color: "#000",
                              fontWeight: "700",
                            }}
                          >
                            {cat.name}
                          </Typography>
                        </Grid>
                      </motion.div>
                    </SwiperSlide>
                  )}
                </>
              );
            })}
        </Swiper>
      </Container>
    </Box>
  );
};

export default Categories;
