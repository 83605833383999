import React, { useEffect, useState } from "react";
import {
  ImageListItemBar,
  Stack,
  Typography,
  Paper,
  Button,
  Breadcrumbs,
  Card,
  TextField,
  InputAdornment,
  Box,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import PetsIcon from "@mui/icons-material/Pets";
import catName from "../ComingSoonCategories";
import ImageListItem, {
  imageListItemClasses,
} from "@mui/material/ImageListItem";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Search } from "@mui/icons-material";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import Categories from "../Categories";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import Popper from "@mui/material/Popper";
import ListView from "../ListView";
const Genetics = ({
  breedsRaw,
  loadBreeds,
  classes,
  loading,
  loadCategories,
  loadGenetics,
  loadGeneticsSubBreeds,
  subBreedGenetics,
  genetics,
  subBreedsRaw,
  loadSubBreeds,
}) => {
  const styleclasses = useStyles();
  const { categoryId, breedId, subBreedId, geneticId } = useParams();
  const [isNavigate, setIsNavigate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [breedGenetics, setBreedGenetics] = useState([]);
  const [view, setView] = useState("Grid");
  const [redirectLoader, setRedirectLoader] = useState(false);
  const [search, setSearch] = useState("");
  const catId = [318, 308, 288, 289, 310];
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isLargeScreen = useMediaQuery("(min-width: 2500px)");
  const isLarge38pxScreen = useMediaQuery("(min-width: 3800px)");

  useEffect(() => {
    if (catId.includes(parseInt(categoryId))) {
      navigate("/classes");
    }
    if (classes.length < 1) {
      loadCategories();
    }
    if (breedsRaw.length < 1) {
      loadBreeds(categoryId);
    }
    if (subBreedsRaw.length < 1) {
      loadSubBreeds(breedId);
    }
    if (genetics.length < 1) {
      if (subBreedId) {
        loadGeneticsSubBreeds(subBreedId);
      } else {
        loadGenetics(breedId);
      }
    }
  }, []);

  const category = classes.filter((cat) => cat.id === parseInt(categoryId))[0];
  useEffect(() => {
    if (category) {
      if (catName.includes(category.name)) {
        navigate("/classes");
      }
    }
  }, [category]);
  const breed = breedsRaw.filter((breed) => breed.id === parseInt(breedId))[0];
  const subBreed = subBreedsRaw.filter(
    (subBreed) => subBreed.id === parseInt(subBreedId)
  )[0];
  const navigate = useNavigate();
  const handleGridView = () => {
    setView("Grid");
  };

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };
  const handleListView = () => {
    setView("List");
  };
  useEffect(() => {
    setBreedGenetics(genetics);
  }, [loading, genetics]);

  useEffect(() => {
    setBreedGenetics(subBreedId ? subBreedGenetics : genetics);
  }, [loading, genetics, subBreedGenetics]);

  useEffect(() => {
    if (isNavigate && !loading) {
      if (subBreedGenetics.length > 0) {
        setRedirectLoader(false);
        navigate(
          `/classes/${categoryId}/breeds/${breedId}/genetics/${geneticId}/subbreeds`
        );
      } else {
        setRedirectLoader(false);
        navigate(`/listings/breed/${breedId}/genetics/${geneticId}`);
      }
    }
  }, [isNavigate, loading]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <>
      {/* <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="top"
        onMouseLeave={handleMouseLeave}
      >
        <Box
          sx={{
            border: 1,
            p: 2,
            bgcolor: "background.paper",
          }}
        >
          <Typography>Name: Moore exotic</Typography>
          <Typography component={"span"}>
            <Typography component={"span"}>Link: </Typography>
            <Typography component={"span"}>
              <a
                href="https://example.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://example.com
              </a>
            </Typography>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="https://img.freepik.com/free-vector/golden-bird-logo-design_1195-336.jpg"
              style={{ height: "60px", width: "60px" }}
            />
          </Box>
        </Box>
      </Popper> */}
      <Stack className={styleclasses.breedsContainer}>
        <Breadcrumbs aria-label="breadcrumb">
          {" "}
          <Link to="/" className={styleclasses.breadcrumbs}>
            Home
          </Link>
          <Link to="/classes" className={styleclasses.breadcrumbs}>
            Classes
          </Link>
          <Link
            className={styleclasses.breadcrumbs}
            to={`/classes/${categoryId}/breeds/`}
            color="primary"
          >
            <Typography>{category?.name}</Typography>
          </Link>
          <Link
            className={styleclasses.breadcrumbs}
            color="primary"
            to={
              subBreedId
                ? `/classes/${categoryId}/breeds/${breedId}/subbreeds`
                : `/classes/${categoryId}/breeds/${breedId}/genetics`
            }
          >
            <Typography>{breed?.name} </Typography>
          </Link>
          {subBreedId && (
            <Link className={styleclasses.breadcrumbs} color="primary">
              <Typography>{subBreed?.name} </Typography>
            </Link>
          )}
        </Breadcrumbs>
        <Typography
          variant="h3"
          component={"h3"}
          sx={{
            color: "",
            fontWeight: 900,
            textAlign: "center",
          }}
        >
          {subBreedId ? subBreed?.name : breed?.name} Genetics
        </Typography>
        {breedGenetics.length > 0 && (
          <Box display={"flex"}>
            <Box sx={{ textAlign: "center" }} flexGrow={1}>
              <TextField
                placeholder="Search your Genetics"
                type="search"
                size="small"
                sx={{
                  width: { xs: "100%", sm: "70%", md: "50%" },
                  mt: 2,
                  backgroundColor: "white",
                  "& .MuiOutlinedInput-root": {
                    paddingRight: 0,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        backgroundColor: "rgb(255, 192, 1)",
                        padding: "27.5px 14px",
                        borderTopLeftRadius: (theme) =>
                          theme.shape.borderRadius + "px",
                        borderBottomLeftRadius: (theme) =>
                          theme.shape.borderRadius + "px",
                      }}
                      position="end"
                    >
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearchChange}
              />
            </Box>
            {!isSmallScreen && (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton aria-label="delete" onClick={handleGridView}>
                  <GridViewIcon sx={{ color: view === "Grid" && "#FFC001" }} />
                </IconButton>{" "}
                <IconButton aria-label="delete" onClick={handleListView}>
                  <ListIcon sx={{ color: view !== "Grid" && "#FFC001" }} />
                </IconButton>{" "}
              </Box>
            )}
          </Box>
        )}
        {isSmallScreen && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton aria-label="delete" onClick={handleGridView}>
              <GridViewIcon sx={{ color: view === "Grid" && "#FFC001" }} />
            </IconButton>{" "}
            <IconButton aria-label="delete" onClick={handleListView}>
              <ListIcon sx={{ color: view !== "Grid" && "#FFC001" }} />
            </IconButton>{" "}
          </Box>
        )}
        {loading || redirectLoader ? (
          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {view === "Grid" ? (
              <>
                {breedGenetics.length > 0 ? (
                  <Card
                    sx={{
                      mt: 1,
                      p: 4,
                      display: "grid",
                      gap: 0.5,
                      gridTemplateColumns: {
                        xs: "repeat(2, 1fr)",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(3, 1fr)",
                        lg: "repeat(4, 1fr)",
                        xl: isLargeScreen
                          ? isLarge38pxScreen
                            ? "repeat(7, 1fr)"
                            : "repeat(6, 1fr)"
                          : "repeat(5, 1fr)",
                      },
                      [`& .${imageListItemClasses.root}`]: {
                        display: "flex",
                        flexDirection: "column",
                      },
                    }}
                  >
                    {breedGenetics &&
                      breedGenetics
                        .filter((item) => {
                          if (search === "") {
                            return item;
                          } else if (
                            item.name
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((item) => (
                          <Paper
                            elevation={24}
                            component={Link}
                            to={`/listings/genetics/${item.id}`}
                          >
                            <ImageListItem
                              key={item.id}
                              sx={{ height: "100% !important" }}
                            >
                              <div className="container">
                                {item.brand_promotions &&
                                  item.brand_promotions.length > 0 && (
                                    <div
                                      className="circular-image"
                                      onMouseEnter={handleMouseEnter}
                                    >
                                      <img
                                        src={item.brand_promotions?.[0]?.image}
                                        alt={item.brand_promotions?.[0]?.name}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                          position: "absolute",
                                          top: "10px",
                                          right: "10px",
                                          border: "2px solid white",
                                          zIndex: 100,
                                          objectFit: "contain",
                                        }}
                                      />
                                    </div>
                                  )}
                                <img
                                  style={{ width: "100%", height: "250px" }}
                                  className="image"
                                  src={`${item.image}`}
                                  srcSet={`${item.image}`}
                                  alt={item.name}
                                  loading="lazy"
                                />
                                <div className="middle">
                                  <div className="layOver"></div>
                                  <div className="button">
                                    <Button
                                      component={Link}
                                      to={`/listings/genetics/${item.id}`}
                                      size="large"
                                      variant="outlined"
                                      sx={{
                                        "&:hover": {
                                          backgroundColor: "rgb(255, 192, 1)",
                                          color: "#000",
                                        },
                                      }}
                                    >
                                      Go Now
                                    </Button>
                                  </div>
                                </div>
                              </div>

                              <ImageListItemBar
                                title={item.name}
                                sx={{ textAlign: "center" }}
                              />
                            </ImageListItem>
                          </Paper>
                        ))}
                  </Card>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        textAlign={"center"}
                        sx={{ fontWeight: "bold" }}
                      >
                        {" "}
                        No Such Genetics Exists
                      </Typography>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <ListView
                categories={breedGenetics}
                page={"genetics"}
                search={search}
              />
            )}
          </>
        )}
        {breedGenetics.length > 0 && (
          <Box sx={{ textAlign: "center" }} mt={2}>
            <Button
              component={Link}
              to={
                subBreedId
                  ? `/listings/subbreed/${subBreedId}`
                  : `/listings/breed/${breedId}`
              }
              size="large"
              variant="contained"
              startIcon={<PetsIcon sx={{ color: "white" }} />}
              sx={{
                width: { xs: "100vw", md: "35vw", lg: "30vw" },
                height: 40,
                color: "#fff",
                fontSize: 18,
                fontWeight: 700,
                textTransform: "none !important",
              }}
            >
              See {subBreedId ? subBreed?.name : breed?.name} Listings
            </Button>
          </Box>
        )}
      </Stack>
    </>
  );
};

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  breedsContainer: {
    backgroundColor: "#ebecf0",
    padding: "24px 5px 24px 5px",
  },
  breadcrumbs: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color: "rgb(255, 192, 1)",
      textDecoration: "underline",
    },
  },
});

const mapStateToProps = ({ Categories }) => ({
  breedsRaw: Categories.breeds,
  classes: Categories.classes,
  loading: Categories.loading,
  genetics: Categories.genetics,
  subBreedGenetics: Categories.subBreedGenetics,
  subBreedsRaw: Categories.subBreeds,
});

const mapDispatchToProps = (dispatch) => ({
  loadBreeds: (categoryId) => dispatch(Actions.thunkLoadBreeds(categoryId)),
  loadGenetics: (breedId) => dispatch(Actions.thunkLoadGenetics(breedId)),
  loadCategories: () => dispatch(Actions.thunkLoadCategories()),
  loadGeneticsSubBreeds: (geneticsId) =>
    dispatch(Actions.thunkLoadSubBreedGenetics(geneticsId)),
  loadSubBreeds: (breedId) => dispatch(Actions.thunkLoadSubBreeds(breedId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Genetics);
