import React, { useEffect, useState } from "react";
import config from "../../../config";
import axios from "axios";
import * as Actions from "../../../redux/actions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DropDown from "../../../components/seller/listings/DropDown";
import DropFileInput from "../../../components/dropFileInput/DropFileInput";
import { TagsInput } from "react-tag-input-component";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import catName from "../../categories/ComingSoonCategories";
import {
  Alert,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Slide,
  Snackbar,
  Checkbox,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CustomModal from "../../../components/seller/listings/Modal";
import SelectedMediaView from "../../../components/seller/listings/SelectedMediaView";
import "./CreateOrEditList.css";

const initialState = {
  name: "",
  color: "",
  location: "",
  birth: "",
  weight: "",
  length: "",
  height: "",
  diet: "",
  price: "",
  new_breed: "",
};

const CreateOrEditList = ({
  classes,
  loadBreeds,
  loadSubBreeds,
  genetics,
  loadGenetics,
  loadSubBreedsGenetics,
  subBreedGenetics,
  breeds,
  subBreeds,
  jwtToken,
  seller,
  store,
  user,
}) => {
  const navigate = useNavigate();
  const styleclasses = useStyles();
  const [sexSelected, setSexSelected] = useState("");
  const [lengthUnitSelected, setLengthUnitSelected] = useState("");
  const [heightUnitSelected, setHeightUnitSelected] = useState("");
  const [weightUnitSelected, setWeightUnitSelected] = useState("");
  const [categorySelected, setCategorySelected] = useState("");
  const [breedSelected, setBreedSelected] = useState("");
  const [subBreedSelected, setSubBreedSelected] = useState("");
  const [geneticsSelected, setGeneticsSelected] = useState("");
  const [geneticsSubBreedSelected, setGeneticsSubBreedSelected] = useState("");
  const [listData, setListData] = useState();
  const [traits, setTraits] = useState(null);
  const [shippingDetails, setShippingDetails] = useState(null);
  const [formValues, setFormValues] = useState(initialState);
  const [processing, setProcessing] = useState(false);
  const [breedsData, setBreedsData] = useState([]);
  const [subBreedsData, setSubBreedsData] = useState([]);
  const [geneticData, setGeneticsData] = useState([]);
  const [subbreedGeneticData, setSubbreedGeneticData] = useState([]);
  const [images, setImages] = useState([]);
  const [PEDImages, setPEDImages] = useState();
  const [previewImages, setPreviewImages] = useState([]);
  const [previewPEDImages, setPreviewPEDImages] = useState([]);
  const [mediaDeletedUrls, setMediaDeletedUrls] = useState([]);
  const [PEDAllowed, setPEDAllowed] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [confirmProp, setConfirmProp] = useState(false);
  const [planLimitMessage, setPlanLimitMessage] = useState("");
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const params = useParams();

  const animalCategories = classes.filter((item) => {
    if (catName.includes(item.name)) {
      return false;
    }
    return true;
  });

  useEffect(() => {
    if (!store) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const getListingData = async (id) => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/animals/${id}/edit`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
      }

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 200) {
        setListData(registerRequestData.data);
      }
    };

    if (params.id) {
      getListingData(params.id);
    }

    if (seller) {
      setPEDAllowed(
        seller.user.subscriptions[0].plan.offers.filter(
          (offer) => offer.name === "PED"
        )[0]?.value
      );
    }
  }, []);

  useEffect(() => {
    if (confirmProp) {
      window.location.href = user.billingPortalUrl;
    }
  }, [confirmProp]);

  useEffect(() => {
    if (params.id && listData) {
      setFormValues({
        name: listData.name,
        color: listData.color,
        location: listData.location,
        birth: listData.birth,
        weight: listData.weight.split(" ")[0],
        length: listData.length.split(" ")[0],
        height: listData.height.split(" ")[0],
        diet: listData.diet,
        price: listData.price,
        new_breed: listData.new_breed,
      });
      setLengthUnitSelected(listData.length.split(" ")[1]);
      setHeightUnitSelected(listData.height.split(" ")[1]);
      setWeightUnitSelected(listData.weight.split(" ")[1]);
      setShippingDetails(listData.shipping_details);
      setTraits(listData.traits);
      setCategorySelected(listData.category_id);
      setGeneticsSelected(listData.genetic_id);
      setSexSelected(listData.sex);
      setBreedSelected(listData.breed_id);
      if (listData.sub_breed_id) {
        setSubBreedSelected(listData.sub_breed_id);
      }
      setPreviewImages(listData.media);
      if (listData.ped.url) {
        setPreviewPEDImages([...previewPEDImages, listData.ped]);
      }
    }
  }, [listData, params.id]);

  useEffect(() => {
    if (categorySelected) {
      loadBreeds(categorySelected);
    }
  }, [categorySelected]);

  useEffect(() => {
    if (breedSelected) {
      // loadGenetics(breedSelected);
      loadSubBreeds(breedSelected);
    }
  }, [breedSelected]);

  useEffect(() => {
    if (breedSelected && subBreeds.length === 0) {
      loadGenetics(breedSelected);
    }
  }, [breedSelected, subBreeds]);

  // useEffect(() => {
  //   if (breedSelected) {
  //     loadSubBreeds(breedSelected);
  //   }
  // }, [breedSelected]);

  useEffect(() => {
    if (subBreedSelected && subBreeds) {
      loadSubBreedsGenetics(subBreedSelected);
    }
  }, [subBreedSelected, subBreeds]);

  useEffect(() => {
    if (categorySelected && breeds) {
      setBreedsData([
        ...breeds,
        {
          id: "other",
          name: "Others",
        },
      ]);
    }
  }, [breeds, categorySelected]);

  useEffect(() => {
    if (breedSelected && breeds) {
      setSubBreedsData(subBreeds);
    }
  }, [subBreeds, breedSelected]);

  useEffect(() => {
    if (breedSelected && breeds && genetics) {
      setGeneticsData(genetics);
      // setSubBreedsData([]);
    }
  }, [breeds, genetics]);

  useEffect(() => {
    if (subBreedSelected && subBreeds) {
      setSubbreedGeneticData(subBreedGenetics);
    }
  }, [subBreedGenetics, geneticsSelected]);

  const handleSetSex = (value) => {
    setSexSelected(value);
  };
  const handleSetLengthUnit = (value) => {
    setLengthUnitSelected(value);
  };
  const handleSetHeightUnit = (value) => {
    setHeightUnitSelected(value);
  };
  const handleSetWeightUnit = (value) => {
    setWeightUnitSelected(value);
  };
  const handleSetCategory = (value) => {
    setCategorySelected(value);
    setBreedSelected("");
    setGeneticsData([]);
    setSubbreedGeneticData([]);
    setSubBreedSelected("");
    setBreedsData([]);
    setSubBreedsData([]);
    setGeneticsSelected("");
  };
  const handleSetBreed = (value) => {
    setBreedSelected(value);
    setSubBreedSelected("");
    setGeneticsSelected("");
    setSubbreedGeneticData([]);
    setSubBreedsData([]);
  };

  const handleSetGenetics = (value) => {
    setGeneticsSelected(value);
  };
  const handleSetGeneticsSubbreed = (value) => {
    setGeneticsSubBreedSelected(value);
  };
  const handleSetSubBreed = (value) => {
    setSubBreedSelected(value);
    setGeneticsSubBreedSelected("");
    setSubbreedGeneticData([]);
  };

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    let breedId = breedSelected;
    if (breedSelected !== "other") {
      formValues.new_breed = "";
    } else {
      breedId = null;
    }

    const data = {
      ...formValues,
      traits: traits,
      shipping_details: shippingDetails,
      breed_id: breedId,
      sub_breed_id: subBreedSelected,
      category_id: categorySelected,
      genetic_id: geneticsSelected,
      sex: sexSelected,
      media: images,
      ped: PEDAllowed ? PEDImages : null,
      length: formValues.length + " " + lengthUnitSelected,
      height: formValues.height + " " + heightUnitSelected,
      weight: formValues.weight + " " + weightUnitSelected,
    };
    let registerRequest;
    try {
      if (params.id) {
        registerRequest = await axios.patch(
          `${config.SERVER_URL}/api/v1/animals/${params.id}`,
          {
            animal: {
              ...data,
              media_deleted_ids: mediaDeletedUrls,
            },
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } else {
        registerRequest = await axios.post(
          `${config.SERVER_URL}/api/v1/seller/${seller.id}/animals`,
          {
            animal: {
              ...data,
            },
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      }
    } catch ({ response }) {
      registerRequest = response;
      if (registerRequest.status === 400) {
        setOpenDeleteModal(true);
        setPlanLimitMessage(registerRequest.data.message);
      } else if (registerRequest.status === 422) {
        setSnackbarStates({
          open: true,
          message: registerRequest.data.message,
          severity: "danger",
        });
      }
      setProcessing(false);
    }

    if (registerRequest.status === 201 || registerRequest.status === 200) {
      setProcessing(false);
      navigate("/seller/listing");
    }
  };

  const onFileChange = async (file) => {
    setImages([...images, file]);
    setPreviewImages([...previewImages, file]);
  };

  const onPEDFileChange = async (file) => {
    setPEDImages(file);
    setPreviewPEDImages([...previewPEDImages, file]);
  };

  const onFileRemove = async (file) => {
    const newImages = images.filter((image) => image !== file);
    setImages(newImages);

    if (file.url) {
      setMediaDeletedUrls([...mediaDeletedUrls, file.url]);
    }

    const newPreviewImages = previewImages.filter((image) => image !== file);
    setPreviewImages(newPreviewImages);
  };

  const onPEDFileRemove = async (file) => {
    setPEDImages(null);

    const newPreviewPEDImages = previewPEDImages.filter(
      (image) => image !== file
    );
    setPreviewPEDImages(newPreviewPEDImages);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarStates({ ...snackbarStates, open: false });
  };
  return (
    <Grid xs={12}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={processing}
      >
        <CircularProgress sx={{ color: "rgb(255 192 1)" }} />
      </Backdrop>
      <Snackbar
        transitionDuration={{ enter: 1000, exit: 2000 }}
        TransitionComponent={Slide}
        TransitionProps={{ enter: true, exit: false }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarStates.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <CustomModal
        title="Plan Limit Exceeded!"
        content={planLimitMessage}
        openModal={openDeleteModal}
        closeModal={handleCloseDeleteModal}
        actionTitle={"Update Plan"}
        actionColor={"primary"}
        setConfirmProp={setConfirmProp}
      />
      <Box
        sx={{
          m: 3,
          p: 2,
          height: "95vh",
          textAlign: "center",
          bgcolor: "white",
          overflow: "auto",
        }}
      >
        <Typography component="h1" variant="h5" fontWeight={"bold"}>
          {params.id ? "Edit Animal Listing" : "Create Animal Listing"}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2} xs={12} sx={{ pl: 2 }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Typography
                  component="p"
                  variant="p"
                  sx={{
                    textAlign: "left",
                    fontSize: "14px",
                    color: "#454545",
                    mb: 1,
                  }}
                >
                  Listing's Media
                </Typography>
                <DropFileInput
                  onFileChange={(file) => onFileChange(file)}
                  filesUrls={previewImages}
                  disabled={false}
                  acceptFileTypes={".jpg, .jpeg, .gif, .png, .webp, .heic"}
                />
                {previewImages && previewImages.length > 0 ? (
                  <SelectedMediaView
                    media={previewImages}
                    onFileRemove={onFileRemove}
                  />
                ) : null}
              </Box>
              {PEDAllowed && (
                <Box sx={{ mb: 2 }}>
                  <Typography
                    component="p"
                    variant="p"
                    sx={{
                      textAlign: "left",
                      fontSize: "14px",
                      color: "#454545",
                      mb: 1,
                    }}
                  >
                    Listing's PED
                  </Typography>
                  <DropFileInput
                    onFileChange={(file) => onPEDFileChange(file)}
                    filesUrls={previewImages}
                    disabled={previewPEDImages.length > 0 ? true : false}
                    acceptFileTypes={".jpg, .jpeg, .gif, .png, .webp, .heic"}
                  />
                  {previewPEDImages && previewPEDImages.length > 0 ? (
                    <SelectedMediaView
                      media={previewPEDImages}
                      onFileRemove={onPEDFileRemove}
                    />
                  ) : null}
                </Box>
              )}
              <TextField
                autoComplete
                size="small"
                name="name"
                fullWidth
                id="name"
                label="Name"
                value={formValues.name}
                onChange={handleFormChange}
                sx={{ mb: 2, mr: 2 }}
                required
              />
              <DropDown
                data={sexTypes}
                title={"Sex"}
                selected={sexSelected}
                handleSelected={handleSetSex}
                disabled={false}
                required={true}
              />
              <DropDown
                data={animalCategories}
                title={"Categories"}
                selected={categorySelected}
                handleSelected={handleSetCategory}
                disabled={false}
                required={true}
              />
              <DropDown
                data={breedsData}
                title={"Breed"}
                selected={breedSelected}
                handleSelected={handleSetBreed}
                disabled={categorySelected ? false : true}
                required={true}
              />
              {breedSelected === "other" && (
                <TextField
                  size="small"
                  fullWidth
                  name="new_breed"
                  label="Other Breed"
                  id="new_breed"
                  value={formValues.new_breed}
                  onChange={handleFormChange}
                  sx={{ mb: 2, mr: 2 }}
                  required
                />
              )}

              {subBreedsData.length > 0 && (
                <DropDown
                  data={subBreedsData}
                  title={"Sub Breed"}
                  selected={subBreedSelected}
                  handleSelected={handleSetSubBreed}
                  disabled={breedSelected ? false : true}
                  required={false}
                />
              )}

              {(geneticData.length > 0 || subbreedGeneticData.length > 0) && (
                <DropDown
                  data={
                    subbreedGeneticData.length > 0
                      ? subbreedGeneticData
                      : geneticData
                  }
                  title={
                    subbreedGeneticData.length > 0 || geneticData.length > 0
                      ? "Genetics"
                      : "Sub-breeds"
                  }
                  selected={geneticsSelected}
                  handleSelected={handleSetGenetics}
                  disabled={
                    breedSelected
                      ? subBreedsData.length > 0
                        ? subBreedSelected
                          ? false
                          : true
                        : false
                      : true
                  }
                  required={false}
                />
              )}

              <TextField
                size="small"
                fullWidth
                name="color"
                label="Color"
                id="color"
                value={formValues.color}
                onChange={handleFormChange}
                sx={{ mb: 2, mr: 2 }}
                required
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <TextField
                  size="small"
                  name="length"
                  label="Length"
                  id="length"
                  type="number"
                  fullWidth
                  value={formValues.length}
                  onChange={handleFormChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {lengthUnitSelected}
                      </InputAdornment>
                    ),
                    classes: {
                      root: styleclasses.numberTextField,
                    },
                  }}
                  sx={{ mb: 2, mr: 2 }}
                  required
                />

                <DropDown
                  data={lengthUnitsData}
                  title={"Length Unit"}
                  selected={lengthUnitSelected}
                  handleSelected={handleSetLengthUnit}
                  disabled={false}
                  required={true}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <TextField
                  size="small"
                  fullWidth
                  name="height"
                  label="Height"
                  id="height"
                  type="number"
                  value={formValues.height}
                  onChange={handleFormChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {heightUnitSelected}
                      </InputAdornment>
                    ),
                    classes: {
                      root: styleclasses.numberTextField,
                    },
                  }}
                  sx={{ mb: 2, mr: 2 }}
                  required
                />
                <DropDown
                  data={heightUnitsData}
                  title={"Height Unit"}
                  selected={heightUnitSelected}
                  handleSelected={handleSetHeightUnit}
                  disabled={false}
                  required={true}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <TextField
                  size="small"
                  fullWidth
                  id="weight"
                  label="Weight"
                  name="weight"
                  type="number"
                  autoComplete
                  value={formValues.weight}
                  onChange={handleFormChange}
                  sx={{ mb: 2, mr: 2 }}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {weightUnitSelected}
                      </InputAdornment>
                    ),
                    classes: {
                      root: styleclasses.numberTextField,
                    },
                  }}
                />
                <DropDown
                  data={weightUnitsData}
                  title={"Weight Unit"}
                  selected={weightUnitSelected}
                  handleSelected={handleSetWeightUnit}
                  disabled={false}
                  required={true}
                />
              </Box>
              <TextField
                size="small"
                fullWidth
                id="birth"
                name="birth"
                autoComplete
                type="date"
                label="Birth"
                value={formValues.birth}
                onChange={handleFormChange}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2, mr: 2 }}
                required
              />
              <TextField
                size="small"
                fullWidth
                name="diet"
                label="Diet"
                id="diet"
                value={formValues.diet}
                onChange={handleFormChange}
                sx={{ mb: 2, mr: 2 }}
                required
              />
              <TextField
                size="small"
                fullWidth
                id="price"
                label="Price"
                name="price"
                type="number"
                autoComplete
                value={formValues.price}
                onChange={handleFormChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  classes: {
                    root: styleclasses.numberTextField,
                  },
                }}
                sx={{ mr: 2, mb: 2 }}
                required
              />
              <TagsInput
                value={traits}
                onChange={setTraits}
                name="Traits"
                placeHolder="Enter Traits"
              />
              <Typography
                variant="p"
                component="p"
                sx={{
                  color: "grey",
                  fontSize: "12px",
                  textAlign: "left",
                  mb: "15px",
                }}
              >
                Enter multiple traits by pressing Enter.
              </Typography>
              <TagsInput
                value={shippingDetails}
                onChange={setShippingDetails}
                name="Shipping_Details"
                placeHolder="Shipping Details"
              />
              <Typography
                variant="p"
                component="p"
                sx={{
                  color: "grey",
                  fontSize: "12px",
                  textAlign: "left",
                }}
              >
                Enter multiple shipping details by pressing Enter.
              </Typography>
            </Grid>
            <Box sx={{ width: "100%" }}>
              <Button
                type="submit"
                variant="contained"
                disabled={processing ? true : false}
                sx={{
                  width: { xs: "70vw", md: "50vw" },
                  backgroundColor: "#FEC20C",
                  "&:hover": { backgroundColor: "black", color: "white" },
                  m: 2,
                }}
              >
                {processing ? (
                  <CircularProgress
                    size="24px"
                    sx={{ color: "rgb(255 192 1)" }}
                  />
                ) : params.id ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};
const useStyles = makeStyles({
  numberTextField: {
    height: "40px",
    backgroundColor: "white",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:hover": {
      borderColor: "rgb(255, 192, 1)",
    },
  },
});

const mapStateToProps = ({ Categories, Auth, Seller, Store }) => ({
  classes: Categories.classes,
  breeds: Categories.breeds,
  jwtToken: Auth.jwtToken,
  user: Auth.user,
  seller: Seller.seller,
  subBreeds: Categories.subBreeds,
  genetics: Categories.genetics,
  subBreedGenetics: Categories.subBreedGenetics,
  store: Store.store,
});

const mapDispatchToProps = (dispatch) => ({
  loadBreeds: (categoryId) => dispatch(Actions.thunkLoadBreeds(categoryId)),
  loadSubBreeds: (breedId) => dispatch(Actions.thunkLoadSubBreeds(breedId)),
  loadGenetics: (breedId) => dispatch(Actions.thunkLoadGenetics(breedId)),
  loadSubBreedsGenetics: (geneticsId) =>
    dispatch(Actions.thunkLoadSubBreedGenetics(geneticsId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditList);

const sexTypes = [
  {
    id: "male",
    name: "Male",
  },
  {
    id: "female",
    name: "Female",
  },
  {
    id: "pair",
    name: "Pair",
  },
];

const lengthUnitsData = [
  {
    id: "meters",
    name: "Meters",
  },
  {
    id: "feets",
    name: "Feets",
  },
  {
    id: "inches",
    name: "Inches",
  },
];
const heightUnitsData = [
  {
    id: "meters",
    name: "Meters",
  },
  {
    id: "feets",
    name: "Feets",
  },
  {
    id: "inches",
    name: "Inches",
  },
];

const weightUnitsData = [
  {
    id: "grams",
    name: "Grams",
  },
  {
    id: "pounds",
    name: "Pounds",
  },
];
