import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import { capitalizeFirstLetter } from "../../utils/Utils";
import Paper from "@mui/material/Paper";
import { Avatar, Typography, Box, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import catName from "./ComingSoonCategories";
import { makeStyles, withStyles } from "@mui/styles";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    color: "inherit",
  },
})(MuiTableCell);

const TableRow = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    cursor: "pointer",
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#aaaaaa" },
  },
})(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 8px 8px 8px",
    margin: "10px 10px 10px 10px",
    overflow: "auto",
    borderRadius: "10px",
    height: "100%",
    // backgroundColor: "transparent",
    // width: "100%",
  },
  root2: {
    padding: "0 8px 8px 8px",
    margin: "10px 10px 10px 10px",
    overflow: "auto",
    borderRadius: "10px",
    height: "100%",
    // width: "50%",
  },
  table: {
    padding: 0,
    margin: 0,
    borderCollapse: "separate",
    borderSpacing: "0px 9px",
    backgroundColor: "white",
  },
  hover: {
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#eeeeee" },
  },
  featuredRrow: {
    backgroundColor: "#FFF2CA",
  },
}));

const ListView = ({
  categories,
  page,
  handleBreedGoNow,
  handleSubBreedGoNow,
  serviceId,
  search,
}) => {
  const styleclasses = useStyles();
  const navigate = useNavigate();

  console.log("Categories", categories);

  const handleNavigateBreedPage = (data) => {
    if (!catName.includes(data.name)) {
      navigate("/classes/" + data?.id + "/breeds");
    }
  };
  const handleNavigateSubServicePage = (data) => {
    navigate("/services/" + data.id);
  };

  const handleNavigateFromBreedPage = (data) => {
    console.log("DATA", data);
    handleBreedGoNow(data.id);
  };
  const handleNavigateGeneticsPage = (data) => {
    navigate(`/listings/genetics/${data}`);
  };
  const handleNavigateSubServiceListing = (data) => {
    navigate(`/services/${serviceId}/subService/${data}/listings`);
  };
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {categories && categories.length > 0 ? (
        <Paper
          className={styleclasses.root}
          elevation={2}
          sx={{ width: { xs: "100%", md: "80%" } }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            margin={"10px"}
          >
            <Paper
              className={styleclasses.root2}
              elevation={4}
              sx={{ width: { xs: "100%", md: "50%" } }}
            >
              <Table stickyHeader className={styleclasses.table}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: { md: "14px", xl: "16px" },
                        fontWeight: "bold",
                      }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: { md: "14px", xl: "16px" },
                        fontWeight: "bold",
                      }}
                    >
                      {(page === "Classes" && "Class") ||
                        (page === "Services" && "Service") ||
                        (page === "breeds" && "Breed") ||
                        (page === "subbreeds" && "SubBreeds") ||
                        (page === "genetics" && "Genetics") ||
                        (page === "subservice" && "SubServices")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories
                    .filter((item) => {
                      if (search === "") {
                        return item;
                      } else if (
                        item.name.toLowerCase().includes(search.toLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((data) => (
                      <TableRow
                        key={data.name}
                        onClick={
                          (page === "Classes" &&
                            (() => handleNavigateBreedPage(data))) ||
                          (page === "Services" &&
                            (() => handleNavigateSubServicePage(data))) ||
                          (page === "breeds" &&
                            (() => handleNavigateFromBreedPage(data))) ||
                          (page === "subbreeds" &&
                            (() => handleSubBreedGoNow(data.id))) ||
                          (page === "genetics" &&
                            (() => handleNavigateGeneticsPage(data.id))) ||
                          (page === "subservice" &&
                            (() => handleNavigateSubServiceListing(data.id)))
                        }
                      >
                        <TableCell
                          align="left"
                          style={{ width: "2%", padding: "5px" }}
                        >
                          <Avatar
                            width={50}
                            height={50}
                            alt={data.name}
                            src={data.image}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Typography component={"span"}>
                            {data.name}
                          </Typography>{" "}
                        </TableCell>
                        <TableCell width={"5%"}>
                          {catName.includes(data.name) && (
                            <Chip
                              label={<Typography>Coming Soon</Typography>}
                              size="small"
                              sx={{
                                mt: "5px",
                                backgroundColor: "#FFC001",
                                color: "#fff",
                                borderRadius: "6px",
                                fontWeight: "bold",
                                fontSize: "12px",
                                wordBreak: "break-word",
                                p: "5px",
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          </Box>
        </Paper>
      ) : (
        <Typography textAlign={"center"} sx={{ fontWeight: "bold" }}>
          {" "}
          No such category exists.
        </Typography>
      )}
    </Box>
  );
};

export default ListView;
